import React from "react";
import PropTypes from "prop-types";
import CircleArrow from "./CircleArrow";
import { Link } from "gatsby";
import "./pagination.sass"

const Pagination = ({ numPages, currentPage, prevPage, nextPage }) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  return (
    <div className="pagination">
      <Link to={!isFirst && prevPage}>
        <CircleArrow disabled={isFirst} direction="left" />
      </Link>
      <p>Strona {currentPage} z {numPages}</p>
      <Link to={!isLast && nextPage}>
        <CircleArrow disabled={isLast} direction="right" />
      </Link>
    </div>
  )
}

Pagination.propTypes = {
  numPages: PropTypes.number,
  currentPage: PropTypes.number,
  prevPage: PropTypes.func,
  nextPage: PropTypes.func,
}

export default Pagination
