import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import NewsOverview from "../components/NewsOverview";
import Pagination from "../components/Pagination";
import "./news.sass";

export const NewsPageTemplate = ({ news, pageContext }) => {

  const { numPages, currentPage } = pageContext
  const prevPage = currentPage - 1 === 1 ? "/news" : "/news/" + (currentPage - 1).toString()
  const nextPage = "/news/" + (currentPage + 1).toString()

  return (
    <div className="section-container news-page">
      <section className="section">
        <NewsOverview news={news} showRedirect={false} />
        <Pagination numPages={numPages} currentPage={currentPage} prevPage={prevPage} nextPage={nextPage} />
      </section>
    </div>
  );
};

NewsPageTemplate.propTypes = {
  news: PropTypes.array,
  pageContext: PropTypes.object,
};

const NewsPage = ({ data, pageContext }) => {
  const { edges } = data.allMarkdownRemark
  return (
    <Layout>
      <NewsPageTemplate news={edges} pageContext={pageContext} />
    </Layout>
  );
};

NewsPage.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default NewsPage;

export const pageQuery = graphql`
  query EventPageTemplate($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: {frontmatter: {templateKey: {eq: "news"}}}
      sort: {fields: frontmatter___publishDate, order: DESC}
      limit: $limit
      skip: $skip
      ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            templateKey
            date
            description
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
            title
            publishDate
          }
        }
      }
    }
  }
  `